import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { DiamondIcon } from '@/components/DiamondIcon'
import { Logo } from '@/components/Logo'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function Header() {
  const router = useRouter()
  useEffect(() => {}, [router.isReady])

  return (
    <header className="relative z-40 pb-11 lg:pt-11">
      <Container className="flex flex-wrap items-center justify-center sm:justify-between lg:flex-nowrap">
        <div className="mt-10 lg:mt-0 lg:grow lg:basis-0">
          <Link href="/">
            <Logo
              className="h-12 w-auto"
              his={{ fill: '#D4AF37' }}
              hers={{ fill: '#D1B000' }}
              gem={{
                fill: 'hsl(0 16% 52%)',
                className: 'animate-spin-hue',
              }}
            />
          </Link>
        </div>
        <div className="order-first -mx-4 flex flex-auto basis-full overflow-x-auto whitespace-nowrap border-b border-primary-600/10 py-4 font-mono text-sm text-primary-900 sm:-mx-6 lg:order-none lg:mx-0 lg:basis-auto lg:border-0 lg:py-0">
          <div className="mx-auto flex items-center gap-4 px-4">
            <p>
              <time dateTime="2023-06-17">June 17, 2023</time>
            </p>
            <DiamondIcon className="h-1.5 w-1.5 overflow-visible fill-current stroke-current" />
            <Link href="/location">Shushwap Highland Escapes</Link>
          </div>
        </div>
        <div className="hidden sm:mt-10 sm:flex lg:mt-0 lg:grow lg:basis-0 lg:justify-end"></div>
      </Container>
    </header>
  )
}
