import React from 'react'
import { Button } from './Button'

import { Container } from '@/components/Container'

import { MdNavigation as NavStartIcon } from 'react-icons/md'
import {
  TbArrowRampLeft2 as NavLeftRampIcon,
  TbArrowRampLeft3 as NavLeftIcon,
  TbArrowNarrowUp as NavStraightIcon,
} from 'react-icons/tb'
import type { IconType } from 'react-icons'

import { classNames } from '@/lib/frontend-utils'

type NavigationStep = {
  id: number
  from: string
  content: string
  latlng: google.maps.LatLngLiteral
  icon: IconType
  iconBackground: string
}

const steps: NavigationStep[] = [
  {
    id: 1,
    from: 'From Highway 1',
    content:
      'Turn off Highway 1 onto Squilax-Anglemont Rd (signs for Scotch Creek, Anglemont, Adams Lake)',
    latlng: { lat: 50.8712044, lng: -119.5804432 },
    icon: NavStartIcon,
    iconBackground: 'bg-secondary-400',
  },
  {
    id: 2,
    from: 'After 3.9 km',
    content:
      'Continue straight onto River Adams Barrage/Squilax-Anglemont Road',
    latlng: { lat: 50.8977535, lng: -119.5926308 },
    icon: NavStraightIcon,
    iconBackground: 'bg-primary-500',
  },
  {
    id: 3,
    from: 'After 13.3 km',
    content: 'Turn left to stay on Squilax-Anglemont Road',
    latlng: { lat: 50.9107856, lng: -119.4606749 },
    icon: NavLeftRampIcon,
    iconBackground: 'bg-primary-500',
  },
  {
    id: 4,
    from: 'After 3.7 km',
    content: 'Turn left onto Leopold Road',
    latlng: { lat: 50.9251854, lng: -119.4195935 },
    icon: NavLeftIcon,
    iconBackground: 'bg-primary-500',
  },
  {
    id: 5,
    from: 'After 2.5 km',
    content: 'Turn left onto Scotch Creek Hlina Forest Service Road',
    latlng: { lat: 50.9409394, lng: -119.4140392 },
    icon: NavLeftIcon,
    iconBackground: 'bg-primary-500',
  },
  {
    id: 6,
    from: 'After 2.1 km',
    content: 'Continue straight until the end of the road',
    latlng: { lat: 50.94481, lng: -119.43638 },
    icon: NavStraightIcon,
    iconBackground: 'bg-green-500',
  },
]

export function Location() {
  return (
    <section id="location" aria-label="Location" className="py-20 sm:py-32">
      <Container>
        <h2 className="mx-auto max-w-2xl text-center font-display text-4xl font-medium tracking-tighter text-primary-900 sm:text-5xl">
          Location
        </h2>
        <GoogleMap></GoogleMap>
      </Container>
    </section>
  )
}

export function GoogleMap() {
  const ref = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  const [directionsService, setDirectionsService] =
    React.useState<google.maps.DirectionsService | null>(null)
  const [directionsRenderer, setDirectionsRenderer] =
    React.useState<google.maps.DirectionsRenderer | null>(null)
  const [navState, setNavState] = React.useState<
    'Navigate' | 'Error' | 'Reset'
  >('Navigate')

  const [navDuration, setNavDuration] = React.useState<string>('Unknown')

  const [baseDirections, setBaseDirections] =
    React.useState<google.maps.DirectionsResult>({ routes: [] })

  const [center] = React.useState<google.maps.LatLngLiteral>({
    lat: 50.94481,
    lng: -119.43638,
  })
  const [initialZoom] = React.useState<number>(11)

  React.useEffect(() => {
    if (ref.current && !map) {
      const map = new google.maps.Map(ref.current, {
        zoom: initialZoom,
        center: center,
        mapTypeId: 'hybrid',
        scrollwheel: false,
        fullscreenControl: false, // remove the top-right button
        mapTypeControl: false, // remove the top-left buttons
        streetViewControl: false, // remove the pegman
        zoomControl: true, // remove the bottom-right buttons
        styles: [
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f2f2f2' }],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [{ color: '#f2f2f2' }, { visibility: 'off' }],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [{ visibility: 'simplified' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [{ color: '#3b82f6' }, { visibility: 'off' }],
          },
        ],
      })

      new google.maps.Polygon({
        paths: [
          { lat: 50.947317902196964, lng: -119.44610113031061 },
          { lat: 50.93998658991438, lng: -119.44609699310921 },
          { lat: 50.939976991084635, lng: -119.43451483580294 },
          { lat: 50.94739003591332, lng: -119.43442780233352 },
        ],
        strokeColor: '#3b82f6',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#3b82f6',
        fillOpacity: 0.35,
      }).setMap(map)

      const directionsSvc = new google.maps.DirectionsService()
      setDirectionsService(directionsSvc)

      const renderer = new google.maps.DirectionsRenderer()
      renderer.setMap(map)
      setDirectionsRenderer(renderer)

      setMap(map)

      directionsSvc.route(
        {
          origin: new google.maps.LatLng(
            50.86872231847361,
            -119.58442938766834
          ),
          destination: center,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setBaseDirections(result as google.maps.DirectionsResult)
            renderer.setDirections(result)
          } else {
            setNavState('Error')
          }
        }
      )
    }
  }, [ref, map, center, initialZoom])

  function handleGetDirections() {
    if (map && directionsService && directionsRenderer) {
      if (!navigator.geolocation) {
        setNavState('Error')
      } else {
        if (navState === 'Reset') {
          setNavState('Navigate')
          directionsRenderer.setDirections(baseDirections)
          map.panTo(center)
          map.setZoom(initialZoom)
        } else {
          navigator.geolocation.getCurrentPosition((pos) =>
            directionsService.route(
              {
                origin: new google.maps.LatLng(
                  pos.coords.latitude,
                  pos.coords.longitude
                ),
                destination: center,
                travelMode: google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                  directionsRenderer.setDirections(result)
                  setNavDuration(
                    (result as google.maps.DirectionsResult).routes[0].legs[0]
                      .duration?.text ?? 'Unknown'
                  )
                  setNavState('Reset')
                } else {
                  setNavState('Error')
                }
              }
            )
          )
        }
      }
    }
  }

  const panTo = React.useCallback(
    (latLng: google.maps.LatLngLiteral) => {
      if (map) {
        if (map.getZoom() != 14) {
          map.setZoom(14)
        }
        map.panTo(latLng)
      }
    },
    [map]
  )

  return (
    <>
      <div className="relative mx-auto h-[600px] w-full rounded">
        <div className="h-full rounded-2xl" ref={ref} />
        <Button className="mt-2 w-full" onClick={handleGetDirections}>
          {
            {
              Navigate: 'Show directions from your location',
              Error: "Sorry, couldn't get directions for you..",
              Reset: `Hide directions (Travel time: ${navDuration})`,
            }[navState]
          }
        </Button>
      </div>
      {/* https://plus.codes/9522WHV7+WG */}
      {/* w3w ///pined.teams.spices */}
      <div className="mx-auto mt-20 max-w-max text-lg">
        We will have signs to help you find your way, since our reception is
        going to be off-grid. The basic instructions to get there are:
        <Navigate className="mt-4" panTo={panTo} steps={steps} />
      </div>
    </>
  )
}

export function Navigate({
  className,
  steps,
  panTo,
  ...props
}: {
  steps: NavigationStep[]
  panTo: (arg0: google.maps.LatLngLiteral) => void
} & React.ComponentProps<'div'>) {
  return (
    <div className={classNames('flow-root', className)} {...props}>
      <ul role="list" className="-mb-8">
        {steps.map((step, stepIdx) => (
          <li key={step.id}>
            <div className="relative pb-8">
              {stepIdx !== steps.length - 1 ? (
                <span
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-secondary-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      step.iconBackground,
                      'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'
                    )}
                  >
                    <step.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-primary-500">
                      <span
                        onClick={() => panTo(step.latlng)}
                        className="cursor-pointer font-medium text-primary-900"
                      >
                        {step.content}
                      </span>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-primary-500">
                    <span>{step.from}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
