import { ComponentProps, SVGProps } from 'react'

export function Logo({
  his,
  hers,
  gem,
  ...rest
}: {
  his?: SVGProps<SVGPathElement>
  hers?: SVGProps<SVGGElement>
  gem?: SVGProps<SVGGElement>
} & ComponentProps<'svg'>) {
  his = his || {}
  hers = hers || {}
  gem = gem || {}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 160" {...rest}>
      <g id="hers" {...hers}>
        <path
          d="M150,42.81c-2.27,2.4-4.54,2.67-7.76,3a42.35,42.35,0,0,1-10,0C109,42.61,86.13,59.15,82.14,82.29a45,45,0,0,0,24.8,48.78c.35.18.7.36,1.32.7l-4.62,7.41a43.61,43.61,0,0,1-10.83-6.67c-15.62-13.18-22.51-30-19.39-50.16S88.18,48.13,107.1,40.52c5-2,10.43-2.76,15.74-3.71a54.06,54.06,0,0,0,17.6-6.08A13.8,13.8,0,0,0,143.57,28a1.3,1.3,0,0,0-.39-2.21,2.76,2.76,0,0,1-.25.57,7.2,7.2,0,0,1-1.25,1.57c-.12.12-1.18-.49-1.19-.79a3.31,3.31,0,0,1,.5-2.27c1.12-1.09,2.47-.65,4,.06l1,3.53c-3.79,5.75-9.07,9.54-15.71,12.23a32.2,32.2,0,0,0,15.61-.35,9.08,9.08,0,0,0,2.45-1.4,2,2,0,0,0,.45-.49Z"
          transform="translate(0 -9.94)"
        />
        <path
          d="M177.35,107.26c-6.24,18.69-19.15,30.53-38.11,35.68a51.06,51.06,0,0,1-23.07.67c-.39-.08-.78-.18-1.16-.29a2,2,0,0,1-.38-.2c1.38-2.52,2.7-5,4.13-7.48.15-.28.86-.37,1.29-.32C134.5,137.1,147.1,133,157.63,123c17.11-16.27,18.26-43.73,2.83-61.61-2.6-3-4.89-6.32-7.33-9.47-2.1-2.72-3.58-5.75-3.14-9.14l5-1.89c-1.4.7-1.51,1.5-.84,3.54a9,9,0,0,0,1.15,2.42q3.24,4.4,6.62,8.67A32.48,32.48,0,0,0,164.33,58c-.47-2.53-1-4.81-1.27-7.11-.45-4.53-1.42-9.14,1.63-13.59L168,36a16.73,16.73,0,0,1,2.12,3,2.9,2.9,0,0,1-.5,2.18,1.22,1.22,0,0,1-2,.15,1.29,1.29,0,0,1,.24-2.08c.22-.17.47-.32.71-.49-.72-1.14-1.64-1.29-2.29-.26a4.86,4.86,0,0,0-.7,2.38c-.2,5.55,1.25,10.83,3.23,15.91s4.24,9.71,6.52,14.48A46.52,46.52,0,0,1,177.35,107.26Z"
          transform="translate(0 -9.94)"
        />
        <path
          d="M159.1,39.38c.11.13.21.29.31.43-.38.37-.7.9-1.15,1.1-1.1.49-1.06,1.29-.63,2.08a1.31,1.31,0,0,0,2.17.33c1.41-1.18,1.17-2.71.67-4.46Z"
          transform="translate(0 -9.94)"
        />
        <path
          d="M147.36,33.41c-1,.88-2,1.48-.49,2.5a1.58,1.58,0,0,1,.29,1.51c-.33.36-1.29.65-1.59.43A2.92,2.92,0,0,1,144.39,36c-.15-1.87,1.13-3,2.6-3.9Z"
          transform="translate(0 -9.94)"
        />
        <g id="gem" {...hers} {...gem}>
          <path
            d="M174.37,33.62c-2.93-1.47-5.62-2.77-8.26-4.16a1.47,1.47,0,0,0-2.05.26c-2.19,2.13-4.44,4.19-6.71,6.23a9.52,9.52,0,0,1-1.68,1l-.25-.34L163.17,28,154,23.27c-.68,3.74-1.31,7.25-2,10.76h-.41a5.25,5.25,0,0,1-.25-1.44c.26-2.84.54-5.68.91-8.5.15-1.13,0-1.83-1.16-2.35-2.1-.93-4.1-2.07-6.15-3.12-.58-.3-1.18-.57-2-1,.74,2.61,7.06,25.17,7.06,25.17Z"
            transform="translate(0 -9.94)"
          />
          <path
            d="M152.11,21.17l-9-4.65,9-6.58Z"
            transform="translate(0 -9.94)"
          />
          <path
            d="M153.48,21.8V10.49l9.11,4.7Z"
            transform="translate(0 -9.94)"
          />
          <path
            d="M164.83,16.37,173.9,21l-9.07,6.6Z"
            transform="translate(0 -9.94)"
          />
          <path
            d="M174.82,32.89l-9-4.66,9-6.54Z"
            transform="translate(0 -9.94)"
          />
          <path d="M163.46,27l-9-4.65,9-6.54Z" transform="translate(0 -9.94)" />
        </g>
      </g>
      <path
        id="his"
        d="M91.7,64.2l5.38-6c21.72,14.21,34.48,46.76,20.25,76.74-14.86,31.34-52.22,44-83.52,28.52C3.38,148.35-8.93,110.29,7,80,23.5,48.5,60,39.89,86.33,52c-1.61,2.15-3.2,4.31-4.86,6.4-.15.19-.82.1-1.19,0a52.16,52.16,0,0,0-38,.47C26.87,65,16.15,76.07,11.21,91.92c-6.33,20.33-2.12,38.78,12.83,54,27.18,27.65,73.63,17.72,87.56-18.44A53.36,53.36,0,0,0,92.9,65.11C92.58,64.88,92.28,64.64,91.7,64.2Z"
        transform="translate(0 -9.94)"
        {...his}
      />
    </svg>
  )
}
