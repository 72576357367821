import { ComponentProps } from 'react'

import { classNames } from '@/lib/frontend-utils'

// Component
function Button({ className, children, ...props }: ComponentProps<'button'>) {
  return (
    <button
      className={classNames(
        'inline-flex justify-center rounded-2xl bg-primary-600 p-4 text-base font-semibold text-white hover:bg-primary-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 active:text-white/70',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export { Button }
