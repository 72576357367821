import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { classNames } from '@/lib/frontend-utils'

export function Footer({ className = '' }: { className?: string }) {
  return (
    <footer className={classNames('py-16', className)}>
      <Container className="flex flex-col items-center justify-between md:flex-row">
        <Logo className="h-12 w-auto text-primary-900" />
        <p className="mt-6 text-base text-primary-500 md:mt-0">
          Copyright &copy; {new Date().getFullYear()} Zev Isert. All rights
          reserved.
        </p>
      </Container>
    </footer>
  )
}
